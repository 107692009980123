.ck-editor {
  ul,
  ol,
  li {
    padding: initial;
    margin: initial;
  }
}

.ck-editor {
  ul,
  ol {
    padding-left: 15px;
  }
}

.ck-editor ul li {
  list-style: disc;
}
.ck-editor ol li {
  list-style: numeric;
}

.ck.ck-editor__editable_inline > :last-child {
  margin-bottom: var(--spacing-6) !important;
}
