// css file taken from appkit4 source, but classnames prefixed with .ck
// otherwise, the specifity was lower than some style coming from another library, causing problems

.ap-text-editor-container {
  box-shadow: var(--box-shadow-1);
  border-radius: var(--border-radius-3);
}

.ck-editor * {
  scrollbar-width: none;
}
.ck-editor *::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.ck-editor .ck.ck-content {
  letter-spacing: -0.025rem !important;
}
.ck-editor .ck.ck-toolbar {
  padding-top: var(--spacing-4);
  height: 4.5rem;
  border-radius: var(--border-radius-3) var(--border-radius-3) 0 0 !important;
  background-color: var(--color-background-container);
  padding-left: var(--spacing-4);
  padding-right: var(--spacing-4);
  padding-bottom: var(--spacing-6);
  border: none;
}
.ck-editor .ck.ck-toolbar .ck-toolbar__items {
  height: 2.5rem;
}
.ck-editor .ck.ck-toolbar .ck-splitbutton__arrow {
  display: none;
}
.ck-editor .ck.ck-toolbar .ck-splitbutton .ck-button:hover {
  border-radius: var(--border-radius-2);
}
.ck-editor .ck.ck-toolbar .ck-font-size-dropdown,
.ck-editor .ck.ck-toolbar .ck-font-family-dropdown {
  width: 2.5rem;
  margin-right: var(--spacing-2) !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.ck-editor .ck.ck-toolbar .ck-font-size-dropdown .ck-button,
.ck-editor .ck.ck-toolbar .ck-font-family-dropdown .ck-button {
  width: 2.5rem;
}
.ck-editor .ck.ck-toolbar .ck-font-size-dropdown .ck-button.ck-on,
.ck-editor .ck.ck-toolbar .ck-font-family-dropdown .ck-button.ck-on {
  background-color: var(--color-background-container);
}
.ck-editor .ck.ck-toolbar .ck-font-size-dropdown .ck-button.ck-on .ck-icon,
.ck-editor .ck.ck-toolbar .ck-font-family-dropdown .ck-button.ck-on .ck-icon {
  color: var(--color-text-heading);
}
.ck-editor .ck.ck-toolbar .ck-font-size-dropdown .ck-button.ck-on:hover,
.ck-editor .ck.ck-toolbar .ck-font-family-dropdown .ck-button.ck-on:hover {
  background-color: var(--color-background-hover);
  border-radius: var(--border-radius-2);
}
.ck-editor .ck.ck-toolbar .ck-button {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: var(--border-radius-2);
  padding: var(--spacing-3) !important;
  margin: 0 var(--spacing-2) 0 0 !important;
  border: none;
  cursor: pointer;
}
.ck-editor .ck.ck-toolbar .ck-button .Appkit4-icon {
  color: var(--color-text-heading);
}
.ck-editor .ck.ck-toolbar .ck-button .icon-link-outline {
  font-size: 1rem !important;
}
.ck-editor .ck.ck-toolbar .ck-button:hover {
  background-color: var(--color-background-hover);
}
.ck-editor .ck.ck-toolbar .ck-button:focus .ck-tooltip {
  visibility: visible;
  display: block;
  opacity: var(--opacity-7);
}
.ck-editor .ck.ck-toolbar .ck-button .ck-tooltip__text {
  background-color: rgba(var(--color-background-default-inverse-rgb), var(--opacity-6));
  padding: var(--spacing-2) var(--spacing-3);
  border-radius: var(--spacing-2);
  font-family: 'PwC Helvetica Neue', sans-serif;
  font-size: 0.875rem;
  letter-spacing: -0.025rem;
  line-height: 1.5rem;
  backdrop-filter: var(--blur-2);
}
.ck-editor .ck.ck-toolbar .ck-button .ck-tooltip__text::after {
  content: none;
}
.ck-editor .ck.ck-toolbar .ck-button:active {
  box-shadow: none;
  background-color: var(--color-background-primary);
}
.ck-editor .ck.ck-toolbar .ck-button:active .ck-icon,
.ck-editor .ck.ck-toolbar .ck-button:active .Appkit4-icon {
  color: var(--color-text-tertiary);
}
.ck-editor .ck.ck-toolbar .ck-button.ck-on {
  background-color: var(--color-background-primary);
}
.ck-editor .ck.ck-toolbar .ck-button.ck-on.ck-disabled {
  opacity: var(--opacity-5);
}
.ck-editor .ck.ck-toolbar .ck-button.ck-on:hover {
  background-color: var(--color-background-primary);
}
.ck-editor .ck.ck-toolbar .ck-button.ck-on .ck-icon,
.ck-editor .ck.ck-toolbar .ck-button.ck-on .Appkit4-icon {
  color: var(--color-text-tertiary);
}
.ck-editor .ck.ck-toolbar .ck-button.ck-disabled {
  cursor: not-allowed;
}
.ck-editor .ck.ck-toolbar .ck-button.ck-disabled:not(.ck-on) {
  background-color: var(--color-background-container);
}
.ck-editor .ck.ck-toolbar .ck-button.ck-disabled .ck-tooltip {
  display: none;
}
.ck-editor .ck.ck-toolbar .ck-button.ck-disabled .ck-button__icon {
  color: var(--color-text-disabled);
  opacity: 1;
}
.ck-editor .ck.ck-toolbar .ck-button.ck-disabled:hover {
  cursor: not-allowed;
}
.ck-editor .ck.ck-toolbar .ck-button.ck-disabled:hover:not(.ck-on) {
  background: transparent;
}
.ck-editor .ck.ck-toolbar .ck-button.ck-disabled:hover {
  cursor: not-allowed;
}
.ck-editor .ck.ck-toolbar .ck-button.ck-disabled:hover:not(.ck-on) {
  background: transparent;
}
.ck-editor .ck.ck-toolbar .ck-button .ck-button__icon {
  color: var(--color-text-body);
  font-size: 1rem;
  height: 1rem;
  width: 1rem;
  font-weight: var(--font-weight-2);
  line-height: 1.5rem;
}
.ck-editor .ck.ck-toolbar .ck-dropdown {
  margin: 0;
}
.ck-editor .ck.ck-toolbar .ck-dropdown .ck-button.ck-on {
  border-radius: var(--border-radius-2);
}
.ck-editor .ck.ck-toolbar .ck-dropdown__panel {
  margin-top: var(--spacing-2);
  width: 240px;
  padding: 0.5625rem var(--spacing-3) var(--spacing-3) var(--spacing-3);
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
  border-radius: var(--border-radius-3);
  border: none;
  box-shadow: var(--level-3);
  background-color: var(--color-background-container);
}
.ck-editor .ck.ck-toolbar .ck-dropdown__panel .ck-list {
  background-color: var(--color-background-container);
}
.ck-editor .ck.ck-toolbar .ck-dropdown__panel .ck-list .ck-list__item {
  margin-bottom: var(--spacing-2);
}
.ck-editor .ck.ck-toolbar .ck-dropdown__panel .ck-list .ck-list__item .ck-button {
  width: 100%;
  height: 2rem;
  margin: 0 !important;
  padding: var(--spacing-2) var(--spacing-3) !important;
  color: var(--color-text-body);
  border-radius: var(--border-radius-2);
}
.ck-editor .ck.ck-toolbar .ck-dropdown__panel .ck-list .ck-list__item .ck-button span {
  font-size: 1rem;
  font-family: 'PwC Helvetica Neue', sans-serif;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5rem;
  letter-spacing: -0.025rem;
}
.ck-editor .ck.ck-toolbar .ck-dropdown__panel .ck-list .ck-list__item .ck-button.ck-on {
  background-color: var(--color-background-hover);
}
.ck-editor
  .ck-toolbar
  .ck-dropdown__panel
  .ck-list
  .ck-list__item
  .ck-button.ck-on
  .ck-button__label {
  font-weight: var(--font-weight-2);
  padding-top: 0.0625rem;
  height: 1.5rem;
}
.ck-editor .ck.ck-toolbar .ck-dropdown__panel .ck-list .ck-list__item .ck-button.ck-on:hover {
  background-color: var(--color-background-hover-selected);
}
.ck-editor .ck.ck-toolbar .ck-dropdown__panel .ck-list .ck-list__item .ck-button:hover {
  background-color: var(--color-background-hover);
}
.ck-editor .ck.ck-toolbar .ck-dropdown__panel .ck-list .ck-list__item:last-child {
  margin-bottom: 0;
}
.ck-editor .ck.ck.ck-editor__editable_inline {
  border: none;
  border-radius: 0 0 var(--border-radius-3) var(--border-radius-3);
  background-color: var(--color-background-container);
  padding-left: var(--spacing-6);
  padding-right: var(--spacing-6);
  letter-spacing: normal;
}
.ck-editor .ck.ck.ck-editor__editable_inline p:first-child {
  margin-top: 0;
}
.ck-editor .ck.ck.ck-editor__editable_inline p:last-child {
  margin-bottom: var(--spacing-6);
}
.ck-editor .ck.ck.ck-editor__editable_inline * {
  color: var(--color-text-body);
  line-height: 1.5rem;
}
.ck-editor .ck.ck.ck-editor__editable_inline a {
  color: var(--color-text-primary);
  text-decoration: underline;
}
.ck-editor .ck.ck.ck-editor__editable.ck-focused {
  box-shadow: none;
  border: none;
}

.ck-body-wrapper .ck-balloon-panel_with-arrow::before {
  content: none !important;
}
.ck-body-wrapper .ck-balloon-panel_with-arrow::after {
  content: none !important;
}
.ck-body-wrapper .ck-balloon-panel {
  border: none;
  box-shadow: var(--level-3);
  border-radius: var(--spacing-3) !important;
  background-color: var(--color-background-container) !important;
}
.ck-body-wrapper .ck-balloon-panel .ck-tooltip {
  display: none !important;
}
.ck-body-wrapper .ck-balloon-panel .ck-balloon-rotator__content .ck-link-form,
.ck-body-wrapper .ck-balloon-panel .ck-balloon-rotator__content .ck-link-actions {
  cursor: not-allowed;
  width: 448px;
  height: 4rem;
  padding: var(--spacing-3);
  margin-left: 0;
}
.ck-body-wrapper .ck-balloon-panel .ck-balloon-rotator__content .ck-link-form .ck-input-text,
.ck-body-wrapper .ck-balloon-panel .ck-balloon-rotator__content .ck-link-actions .ck-input-text {
  background-color: var(--color-background-container);
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-labeled-field-view_empty
  + .ck-button,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-labeled-field-view_empty
  + .ck-button {
  pointer-events: none;
  cursor: not-allowed;
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-labeled-field-view_empty
  + .ck-button:hover,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-labeled-field-view_empty
  + .ck-button:hover {
  background-color: var(--color-background-container);
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-labeled-field-view_empty
  + .ck-button
  .ck-button__icon,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-labeled-field-view_empty
  + .ck-button
  .ck-button__icon {
  height: 1rem;
  width: 1rem;
  color: var(--color-text-body);
  opacity: var(--opacity-6);
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-labeled-field-view_empty
  + .ck-button
  + .ck-button,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-labeled-field-view_empty
  + .ck-button
  + .ck-button {
  cursor: not-allowed;
  pointer-events: none;
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-labeled-field-view_empty
  + .ck-button
  + .ck-button:hover,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-labeled-field-view_empty
  + .ck-button
  + .ck-button:hover {
  background-color: var(--color-background-container);
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-labeled-field-view_empty
  + .ck-button
  + .ck-button
  .ck-button__icon,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-labeled-field-view_empty
  + .ck-button
  + .ck-button
  .ck-button__icon {
  height: 1rem;
  width: 1rem;
  color: var(--color-text-body);
  opacity: var(--opacity-6);
}
.ck-body-wrapper .ck-balloon-panel .ck-balloon-rotator__content .ck-link-form .ck-button,
.ck-body-wrapper .ck-balloon-panel .ck-balloon-rotator__content .ck-link-actions .ck-button {
  width: 3rem;
  height: 3rem;
  margin-left: var(--spacing-3);
  padding: 0;
  border: none;
  box-shadow: none;
}
.ck-body-wrapper .ck-balloon-panel .ck-balloon-rotator__content .ck-link-form .ck-button:hover,
.ck-body-wrapper .ck-balloon-panel .ck-balloon-rotator__content .ck-link-actions .ck-button:hover {
  cursor: pointer;
  background-color: var(--color-background-hover);
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-button
  .ck-button__icon,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-button
  .ck-button__icon {
  height: 1rem;
  width: 1rem;
  line-height: 1.5rem;
  color: var(--color-text-body);
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-link-actions__preview,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-link-actions__preview {
  box-shadow: 0 0 0 0.0625rem var(--color-background-border);
  padding: 0;
  text-align: left;
  line-height: 3rem;
  vertical-align: middle;
  border: none;
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-link-actions__preview
  .ck-button__label,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-link-actions__preview
  .ck-button__label {
  position: absolute;
  font-family: 'PwC Helvetica Neue', sans-serif;
  color: var(--color-text-primary);
  height: var(--spacing-5);
  line-height: var(--spacing-5);
  font-size: 1rem;
  bottom: var(--spacing-3);
  left: var(--spacing-3);
  padding: 0;
  text-decoration: underline;
  text-align: left;
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-link-actions__preview
  .ck-input-text-label,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-link-actions__preview
  .ck-input-text-label {
  position: absolute;
  top: var(--spacing-3);
  left: var(--spacing-3);
  font-family: 'PwC Helvetica Neue', sans-serif;
  height: var(--spacing-4);
  line-height: var(--spacing-4);
  color: var(--color-text-body);
  opacity: var(--opacity-6);
  font-size: var(--spacing-4);
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck.ck-labeled-field-view
  > .ck.ck-labeled-field-view__input-wrapper
  > .ck-input-text_empty.ck-input_focused
  + .ck-label,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck.ck-labeled-field-view
  > .ck.ck-labeled-field-view__input-wrapper
  > .ck-input-text_empty.ck-input_focused
  + .ck-label {
  transform: translate(8px, 0) scale(0.75);
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-labeled-field-view__input-wrapper,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-link-actions__preview,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-labeled-field-view__input-wrapper,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-link-actions__preview {
  width: 20rem;
  height: 3rem;
  display: block;
  margin-left: 0;
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-labeled-field-view__input-wrapper
  .ck-input,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-link-actions__preview
  .ck-input,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-labeled-field-view__input-wrapper
  .ck-input,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-link-actions__preview
  .ck-input {
  width: 20rem;
  font-family: 'PwC Helvetica Neue', sans-serif;
  letter-spacing: -0.025rem;
  height: 3rem;
  transform: none;
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-border);
  border: none;
  padding: var(--spacing-5) var(--spacing-3) 0 var(--spacing-3);
  line-height: var(--spacing-5);
  font-size: 1rem;
  border-radius: var(--border-radius-2);
  color: var(--color-text-body);
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-labeled-field-view__input-wrapper
  .ck-input:focus,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-link-actions__preview
  .ck-input:focus,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-labeled-field-view__input-wrapper
  .ck-input:focus,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-link-actions__preview
  .ck-input:focus {
  padding-top: var(--spacing-3);
  border: none;
  box-shadow: inset 0 0 0 0.0625rem var(--color-background-primary),
    0 0 0 0.25rem rgba(var(--color-background-primary-rgb), 0.12);
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-labeled-field-view__input-wrapper
  .ck-input-text_empty,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-link-actions__preview
  .ck-input-text_empty,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-labeled-field-view__input-wrapper
  .ck-input-text_empty,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-link-actions__preview
  .ck-input-text_empty {
  padding-top: 0;
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-labeled-field-view__input-wrapper
  :not(.ck-input_focused).ck-input-text_empty
  + .ck-label,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-link-actions__preview
  :not(.ck-input_focused).ck-input-text_empty
  + .ck-label,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-labeled-field-view__input-wrapper
  :not(.ck-input_focused).ck-input-text_empty
  + .ck-label,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-link-actions__preview
  :not(.ck-input_focused).ck-input-text_empty
  + .ck-label {
  position: absolute;
  font-family: 'PwC Helvetica Neue', sans-serif;
  letter-spacing: -0.025rem;
  top: var(--spacing-3);
  width: 304px;
  height: 1.5rem;
  opacity: var(--opacity-6);
  font-size: 1rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5rem;
  color: var(--color-text-body);
  background-color: var(--color-background-container);
}
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-labeled-field-view__input-wrapper
  .ck-label,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-form
  .ck-link-actions__preview
  .ck-label,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-labeled-field-view__input-wrapper
  .ck-label,
.ck-body-wrapper
  .ck-balloon-panel
  .ck-balloon-rotator__content
  .ck-link-actions
  .ck-link-actions__preview
  .ck-label {
  padding: 0;
  font-family: 'PwC Helvetica Neue', sans-serif;
  letter-spacing: -0.025rem;
  position: absolute;
  left: 0 !important;
  top: var(--spacing-3) !important;
  max-width: calc(100% - var(--spacing-5));
  line-height: 1rem;
  font-size: 1rem;
  color: rgba(var(--color-text-heading-rgb), var(--opacity-6));
  pointer-events: none;
  display: inline-block;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: var(--color-background-container) !important;
}

.ck.ck-labeled-field-view.ck-labeled-field-view_empty:not(.ck-labeled-field-view_focused):not(.ck-labeled-field-view_placeholder)
  > .ck.ck-labeled-field-view__input-wrapper
  > .ck.ck-label {
  transform: translate(8px, 4px) scale(1) !important;
}

.ck.ck-labeled-field-view > .ck.ck-labeled-field-view__input-wrapper > .ck.ck-label {
  transform: translate(8px, 0) scale(0.75) !important;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-rounded-corners {
  border-radius: 0 0 var(--border-radius-3) var(--border-radius-3);
}
